.project-edit-form {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #ffffff;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    color: #555;
}

input[type="text"],
textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s;
}

textarea {
    min-height: 100px;
    resize: vertical;
}

input[type="file"] {
    margin-top: 0.5rem;
}

.image-preview {
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    margin-top: 1rem;
    border-radius: 4px;
}

.submit-btn {
    display: block;
    width: 100%;
    padding: 0.8rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #45a049;
}

input:focus,
textarea:focus {
    outline: none;
    border-color: #4CAF50;
}